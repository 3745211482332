<template>
  <v-app>
    <v-app-bar app color="#6A76AB" dark src="https://picsum.photos/1920/1080?random">
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(100,115,201,.7), rgba(25,32,72,.7)"
        ></v-img>
      </template>
      <v-container class="py-0 fill-height">
        <v-avatar color="warning lighten-2" size="44" class="mr-10">K</v-avatar>
        <v-toolbar-title>Балабақша</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark text
              v-bind="attrs"
              v-on="on"
            >
              {{languages[selectedLanguage]}}
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              link
              v-for="(item, index) in languages"
              :key="index"
              @click="changeLanguage(index)"
            >
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn dark text @click="logout">Выход</v-btn>
      </v-container>
    </v-app-bar>
    <v-main class="grey lighten-3">
    <v-container class="continer pt-8">
      <div class="div1">
          <v-card class="card">           
            <v-img class="card-img" src="https://picsum.photos/seed/picsum/200/200?random"></v-img>
            <v-card-title v-if="userInfo" class="title">
              {{userInfo.fio.value}}
            </v-card-title>
          </v-card>
          <v-card class="menu-card">
                <v-list>
                  <v-list-item>  
                      <v-list-item-title class="list-title"><router-link to="/parent/news">Объявление</router-link></v-list-item-title> 
                  </v-list-item>
                  <v-list-item active="border">                
                      <v-list-item-title class="list-title"><router-link to="/parent/profile">Профиль</router-link></v-list-item-title>                    
                  </v-list-item>
                  <v-list-item> 
                      <v-list-item-title class="list-title"><router-link to="/parent/children">Информация о ребенке</router-link></v-list-item-title>  
                  </v-list-item>
                  <v-list-item>                  
                      <v-list-item-title class="list-title"><router-link to="/parent/queue-requests">Постановка в очередь</router-link></v-list-item-title>
                  </v-list-item>
                  <v-list-item>                  
                      <v-list-item-title class="list-title"><router-link to="/parent/ddo-requests">Подача документов в ДДО</router-link></v-list-item-title>
                  </v-list-item>
                  <v-list-item>                   
                      <v-list-item-title class="list-title"><router-link to="/parent/auction">Бюллетень освободившихся мест</router-link></v-list-item-title> 
                  </v-list-item> 
                  <v-list-item>
                    <v-list-item-title class="list-title">
                      <v-btn class="buttonExit">Шығу</v-btn>
                    </v-list-item-title>                   
                  </v-list-item>
                </v-list>
          </v-card>
      </div>
      <div class="div2">
          <router-view></router-view>
      </div>
    </v-container>
  </v-main>
  </v-app>
</template>


<script>
  export default {
    mounted() {
      this.getUserInfo();
    },
    computed: {
      selectedLanguage() {
        return this.$store.getters.getLanguage;
      }
    },
    data: () => ({
      userInfo: null,
      languages: {
        'kk': 'Қазақша',
        'ru': 'Русский'
      }
    }),
    methods: {
      async getUserInfo() {
        await this.$http.get('/profile').then((response) => {
          this.userInfo = response.data.data.profile;
        });
      },
      changeLanguage(lang) {
        this.$store.commit('setLanguage', lang);
        location.reload();
      },
      logout() {
        this.$store.dispatch('logout');
      }
    }
  };
</script>
<style>
  .remove-bg > .v-tabs-bar {
    background-color: transparent !important;
  }
</style>
<style scoped>
.card{
  background: #FAFAFB;
  max-width: 350px;
  width: 350px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: 296px;
}
.card .card-img{
  max-width: 152px;
  max-height: 152px;
  width: 152px;
  left: 76px;
  top: 32px;
}
.card .title{
  margin-top: 34px;
  width: 256px;
  font-family: Gill Sans;
  text-align: center;
  font-size: 22px;
  line-height: 25px;
  padding-left: 76px;
  padding-bottom: 48px;
  
}
.menu-card{
  max-width: 350px;
  width: 350px;
  height: 350px;
  background: #FAFAFB;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-top: 26px;
  padding: 24px 0px 33px 0px;
}
.menu-card .list-title{
  text-align: center;
  font-size: 22px;
  line-height: 25px;
  color: #000000;
}
.menu-card .list-title .buttonExit{
  width: 136px;
  height: 32px;
  background: #203D89;
  border-radius: 10px;
  color: #FFFFFF;
}
.div1{
  width: 400px;
  float: left;
}
.div2{
  width: calc(100% - 400px);
  float: right;
}
@media screen and (max-width: 1264px){
  .div1{
    display: none;
  }
  .div2{
    width: 100%;
  }
}
</style>

